import {Component, OnInit} from '@angular/core';
import {environment} from "../environments/environment";
import {DataService} from "../shared/services/data.service";
import {ApiRequestService} from "../shared/services/api-request.service";
import {AppDataService} from "../shared/services/app-data.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'etisalat-flow-studio-fe';
  constructor(public dataService: DataService, public apiRequest: ApiRequestService,
              public appDataService: AppDataService) {}

  ngOnInit() {
    this.appDataService.setDomain();
    this.apiRequest.getUserData();
  }

}
