<div class="invalidAccess">
  <img src="../../../assets/images/lockedDoc.png" alt=""/>
  <ng-container *ngIf="dataService.user">
    <h3>Requested access from wrong email address.</h3>
    <p>Your account is not allowed to view this Buildcard<sup class="tm light">®</sup>.</p>
    <p>Kindly check!</p>
    <p>You are signed in as <a>{{ dataService.user?.email }}</a></p>
  </ng-container>
  <ng-container *ngIf="!dataService.user">
    <p>You are not signed in.</p>
    <p>To view your card, sign in now from top right.</p>
  </ng-container>
</div>
