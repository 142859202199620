<div class="phoneNumberField" [ngClass]="{'redBorder' : (isSaveButtonClicked ||mobileNumber.touched) &&(!contactNumber || (contactNumber && checkIfPhoneInValid()))}">
  <div class="countryFlagCode" (click)="toggleCountriesList($event)">
    <span class="selectedFlag flag-icon flag-icon-squared" [class]="'flag-icon-' + dataService.countryISOCode?.toLowerCase()"></span>
    <div class="countryFlagCodeDropdown" *ngIf="showCountriesList">

      <ul *ngIf="countriesData">
        <li *ngFor="let country of countriesData | searchCountries: countryCodeOrName" (click)="setPhoneNumber(country, $event)">
          <span class="flag-icon flag-icon-squared" [class]="'flag-icon-' + country.code.toLowerCase()"></span>
          {{ country.name }} <span class="countryCode">{{ country.dial_code }}</span>
        </li>
      </ul>
    </div>
  </div>
  <input class="inputPhone" #mobileNumber="ngModel" name="mobileNumber"[(ngModel)]="contactNumber" [disabled]="disableField"
         [ngClass]="{'errorInput1': (mobileNumber.touched && !contactNumber) || (contactNumber && checkIfPhoneInValid())}"
         (keypress)="acceptNumberOnly($event)" (keyup)="acceptNumberOnly($event); setFlagForDialCode()" required>
  <input [ngClass]="{'hide-search-input': !showCountriesList}" class="searchByName" id="hiddenInputBox"
         [(ngModel)]="countryCodeOrName" #searchNameOrCode name="fname"
         #fname="ngModel" (keypress)="acceptAlphaNumeric($event)">
</div>
<div class="inputfield phoneNo">
  <div class="errorMsgBox">
    <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && contactNumber && checkIfPhoneInValid())">Please enter valid contact</span>
    <span class="errorMsg" *ngIf="((isSaveButtonClicked ||mobileNumber.touched) && !contactNumber)">please enter your contact info</span>
  </div>
</div>
<!-- comments -->
