import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EtisalatUserService {

  isEtisalatUserFetched = false;
  userAndKickOffDetails: any;
  isInvalidAccess = false;
  token: string

  constructor() { }
}
