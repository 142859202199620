import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCountries'
})
export class SearchCountriesPipe implements PipeTransform {

  transform(items: any, searchText?: any): any {
    if (!items) { return []; }
    if (!searchText || (searchText === '+')) { return items; }
    return items.filter(item => {
      if (!parseFloat(searchText)) {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      } else {
        return item.dial_code.includes(searchText);
      }
    });
  }

}
