import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(private httpClient: HttpClient) { }

  get(url: string, headers?: HttpHeaders, requestOptions?: any): Observable<any> {
    if (requestOptions) {
      return this.httpClient.get(url, requestOptions).pipe(
        map(res => this.parseResponse(res)));
    } else if (headers) {
      return this.httpClient.get(url, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.httpClient.get(url).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }

  }


  post(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    if (headers) {
      return this.httpClient.post(url, data, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.httpClient.post(url, data).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  put(url: string, data: any, headers?: HttpHeaders): Observable<any> {
    if (headers) {
      return this.httpClient.put(url, data, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.httpClient.put(url, data).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  delete(url: string, headers?: HttpHeaders): Observable<any> {
    if (headers) {
      return this.httpClient.delete(url, { headers: headers }).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    } else {
      return this.httpClient.delete(url).pipe(
        map(res => this.parseResponse(res)), catchError(this.handleError), );
    }
  }

  parseResponse(res: any): any {
    return res ? res : '';
  }

  handleError(error: any) {
    return observableThrowError(error);
  }
}
