import { Component, OnInit } from '@angular/core';
import {DataService} from "../../services/data.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showDropdown = false;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    this.checkIfUser();
  }

  checkIfUser() {
    return this.dataService.user;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  gotoDashBoard() {
    window.location.href = ((` ${environment.STUDIO_FRONTEND_URL}dashboard?is_etisalat_flow=true`));
  }

  logoutUser() {
    this.dataService.unsetCookie();
    window.location.href = ((` ${environment.STUDIO_FRONTEND_URL}dashboard?is_etisalat_flow=true`));
  }

}
