import {Inject, NgModule, PLATFORM_ID} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {RouterModule} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {isPlatformBrowser} from "@angular/common";
import {CommonService} from "../shared/services/common.service";
import {EtisalatModule} from "./etisalat-flow/etisalat.module";
import {FaqComponent} from "./faq/faq.component";
import {MsaComponent} from "./msa/msa.component";

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    MsaComponent
  ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        RouterModule,
        SharedModule,
        EtisalatModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(public commonService: CommonService, @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId) && localStorage ) {
      this.commonService.isPlatformBrowser = true;
    }
  }

}
