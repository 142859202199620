import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-msa',
  templateUrl: './msa.component.html',
  styleUrls: ['./msa.component.scss']
})
export class MsaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

  getFAQsURL() {
    return (environment.ETISALAT_FE_URL + 'faq');
  }
}
