<div class="headerPart">
    <div class="logo"><img src="../../../assets/images/engineer-logo.svg" /></div>
    <div class="userPanel" *ngIf="checkIfUser()" (click)="toggleDropdown()">
        <div class="userName">
          {{ dataService.user.first_name.charAt(0) + '' + dataService.user.last_name.charAt(0) }}
        </div>
       <!-- <div class="dropdownLinks" *ngIf="showDropdown">
            <ul>
                <li (click)="gotoDashBoard()">Dashboard</li>
                <li (click)="logoutUser()">Logout</li>
            </ul>
        </div>-->
    </div>
</div>
