<div class="etisalatfaq">
    <div class="logoLarge"><img src="../../assets/images/logo-large.jpg" alt=""></div>
    <div class="contentText">
    <h2>Etisalat Apps360 Builder Studio Store Customer FAQs</h2>
    <p>These FAQs apply to customers who purchase their Apps 360 Gold or Platinum plan via Etisalat and are incorporated by reference to the Builder Studio Store Customer Policies, and the Store Terms & Conditions with us (collectively, the “Agreement”).</p>

    <h3>What is Builder Studio Store?</h3>
    <p>Builder.ai is the next gen software development company for every idea and company on the planet. During the pandemic, it became clear that the small businesses who desperately needed to be online – also needed extra help to get there. That’s how Studio Store was born.</p>
    <p>Studio Store apps come with pre-packaged features to solve different business challenges. They remove the barriers that might be holding you back from moving online – whether that’s cost (your 12 monthly payment options), technical skills (you don’t need any, and our team are on hand to help you throughout) or time (apps can be ready in as little as 2 weeks, depending on what you choose).</p>  
    <p>Empower your business with products like: ecommerce, eLearning, service appointment booking, food delivery or to tele-consultations – built by Builder.ai.</p>
    </div>

    <div class="contentText">
    <h3>What happens after I buy the product?</h3>
    <p>After you buy your product with Etisalat, the following onboarding process will commence;</p>
        <ol>
            <li><strong>Verification</strong> –We will contact you via email and/or SMS to verify your purchase and schedule a Kick-off call (we might give you a nudge if we don’t hear from you!)</li>
            <li><strong>Kick-off call:</strong> Our customer support team will walk you through the setup process for your online store. Your Etisalat billing starts here.</li>
            <li><strong>Get access</strong> to admin panel, website with dummy info and our how-to guides</li>
            <li><strong>3rd Party Solutions</strong> - We have included out of the box solutions for payments, delivery & SMS solutions, you will need to sign-up with each of these partners to activate your account. Fees charged by 3rd Party solutions e.g. payment transaction fees and delivery fees will be the responsibility of the merchant.</li>
            <li><strong>Setup</strong> your store by uploading your product inventory, your logo and brand designs, and your third-party account details</li>
            <li><strong>Customer support:</strong> Our customer success team is on hand to help you get set up if you have any questions</li>
            <li><strong>Preview your app & website:</strong> With one easy click, you can preview what your online website will look like, before we publish it live to your chosen domain address</li>
            <li><strong>Your website is live</strong> once we’ve published it to Builder’s sub-domain. We can further map it to your chosen domain</li>
            <li><strong>Setup your apps:</strong> Fill in form to provide us details about your store and we’ll submit it to the app stores along with your apps</li>
            <li><strong>Your apps are live</strong> once the apps stores approve the submission</li>
        </ol>
    <p>We make no guarantee that we’ll be able to complete your Store, website, or app under that estimated timeline. Be aware that any comments or documentation about potential time frames and anything that refers to schedule or timeline – are estimates and opinions only.</p>
    <p>We take our duties and responsibilities seriously, so we make commercially reasonable efforts to get you live as soon as possible.</p>
    </div>

    <div class="contentText">
    <h3>What if I need more features, integrations, and customisation?</h3>
    <p>It depends on exactly what you’re after – and that’s why it’s good to chat through what you need with one of our experts. You’ve 2 options:</p>
        <ol>
            <li>If you are looking for extra functionalities not covered by the App360 Studio Store product you purchased, you can add from a select list of available features under our Store+ offering. Chat to our team and they’ll get you started.<br>
Note, these features need to be built and come at an additional cost and with added timeline to deliver. However, you cannot remove from the base features.
</li>
            <li>Want something that’s completely custom or do we need lots of extra features and a completely different design? Amazing, we can sort that for you, no problem. However, Studio Store probably isn’t the right product for you. Please get in touch with your Etisalat Sales rep and they will guide you on further steps.</li>
        </ol>
    <p>We’re not responsible for third-party services, so use them at your own risk. If you choose to use third-party services on the Builder.ai platform, you consent to us sharing your data (and potentially the data of your customers) to those services. If you use third-party services, you agree that we do not provide a warranty – so do get advice beforehand.</p>
    </div>

    <div class="contentText">
    <h3>Payment for your Apps 360 Products</h3>
    <p>All charges relating to the Apps 360 Builder Studio Store products purchased will be based on the plan and payment terms agreed with Etisalat at the time of purchase.</p>
 
    <p>If on your journey with us you purchase additional features outside your base package but within our add-on Store+ offering, you will be charged an additional one-time cost payable directly to Builder at the time of purchase.</p>
    <p>A valid payment method (like a credit card) to cover all fees, including the product, transaction and any additional fees will be required.</p>
    
    <p>Any transaction fees from payment vendors or additional fees from third-party services needs to be paid by you and is separate from your Apps 360 subscription.</p>
    
    <p>You are responsible for all taxes relating to your Studio Store or use of the Services. You may be required to remit taxes to Builder.ai or to self-remit to your local tax authority.</p>
    <p><strong>What about others that work with me or my customers?</strong><br>
        Your business is responsible for both:</p>
        <ol>
            <li>Ensuring your employees, agents and subcontractors comply with the Agreement.</li>
            <li>Any breach of the Agreement by your employees, agents or subcontractors.</li>
        </ol>

     <p>You, as the business, agree that you’ll be responsible for the performance of all your obligations under the Agreement, regardless of whether you sublicense or subcontract any such obligations to any third-party (including but not limited to, any of your affiliates or subsidiaries).</p>

    <p>You are responsible for your Store, the goods or services you sell, and your relationship with your customers, not us.</p>
    </div>

    <div class="contentText">
    <h3>What about Payment Services, Domains, and Logistics?</h3>
    <p>We automatically create certain accounts for you to accept payments. You are responsible for activating and deactivating these accounts and complying with their terms, which may be with various third parties, including but not limited to Payment Gateway, Shipping Partner, Domain Registrar.</p>
    
    <p>Payment Gateway is third party technology and services (e.g., payment processing portals) used to accept debit or credit card purchases from your customers.</p>
    
    <p>Shipping Partner means third party service providers that engage in transportation of goods and may include preparation and arrangement of the space for the goods.</p>
    
    <p>Domain Registrar are service providers that handle the reservation of Internet domain names as well as the assignment of IP addresses for those domain names.</p>
    
    <p>Upon purchasing a domain name through Builder, domain registration will be preset to automatically renew each year so long as your Builder Account remains active. You acknowledge that it is your sole responsibility to deactivate the auto-renewal function should you choose to do so.</p>
    
    <p><strong>Support</strong> - Your Studio Store apps comes with Builder Care included for the duration of your fully paid Apps 360 subscription.<br>
    Builder Care offers 24/7 uptime and performance monitoring, application bug fixes, platform updates for iOS and Android, security patches and back-up management.</p>
    </div>

    <div class="contentText">
    <h3>Ownership & usage of content on the website & app?</h3>
    <p>Note, anything you upload to your Store remains yours (if it was yours) and is your responsibility, but we can use and publish the things you upload. Anything uploaded to Builder.ai remains the property and responsibility of its initial owner. However, we’ll receive a licence of materials published through our platform that we may use to operate and promote our Services.</p>
    </div>

    <div class="contentText">
    <h3>What if I want to renew or cancel my subscription?</h3>
    <p>Please contact Etisalat to get more information about the renewal or termination of your subscription plan.</p>
    </div>

    <div class="contentText">
    <h3>What are my responsibilities?</h3>
    <p>You acknowledge and agree to provide public-facing contact information, a refund policy, order fulfilment timelines, and any other applicable local requirements (e.g., privacy policy) on your Store.</p>
 
    <p>You acknowledge and agree that the Builder Services are not a marketplace, and any contract of sale made through the Builder Services and your Studio Store is directly between you and the customer or end user.</p>
    
    <p>You are the seller of record for all items you sell through your Studio Store. You are responsible for the creation and operation of your Studio Store, your materials and information, the goods and services that you may sell through your Studio Store and all aspects of the transactions between you and your customer(s). This includes, but isn’t limited to, authorising the charge to your customer in respect of their purchase, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where you offer products or services for sale), or your breach of our Agreement.</p>
    
    <p>You represent and warrant that your Studio Store, your materials and information and the goods and services you sell through your Studio Store will be true, accurate, and complete, and won’t violate any applicable laws, regulations or rights of third-parties. Just to be clear, Builder.ai is not the seller or merchant of record and will have no responsibility for your Store or items sold to customers through your Studio Store.</p>
    
    <p>You are solely responsible for the goods or services that you may sell through your Studio Store (including description, price, fees, tax that you calculate, defects, required legal disclosures, regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations.</p>
    
    <p>You may not use Builder Services for any illegal or unauthorised purpose nor may you, in the use or operation of your Studio Store, violate any laws in your jurisdiction (including but not limited to copyright laws), the laws applicable to you in your customer’s jurisdiction, or the laws of the Builder.ai contracting entity. You will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any licence or permit that may be necessary to operate your store or that may be held by you) in your use of your Studio Store and your performance of obligations under the Agreement.</p>
    
    <p>You should be familiar with and you must comply with our Acceptable Use Policy <a href="https://www.builder.ai/terms/acceptable-use-policy" target="_blank">here</a>, which is available at <<a href="https://www.builder.ai/terms/acceptable-use-policy" target="_blank">https://www.builder.ai/terms/acceptable-use-policy</a>>.</p>
    
    <p>You shall not disparage us during the terms of your engagement or contract with us.  If you have an issue or concern with your experience, we welcome feedback and invite you to chat with us privately. For purposes of this section, “disparage” shall mean any negative statements, reviews, comments, or feedback, whether written or oral, about Builder and its services, products, employees, and partners.</p>
    </div>


    <div class="contentText">
    <h3>What are Builder’s Rights?</h3>
    <p>You should be aware that Builder has the right to control who we make our Services available to and we can modify them at any time.</p>
    
    <p>Our Services have a range of features and functionalities, including what is included in the Studio Store service. Not all Services or features will be available to all Builder customers at all times and we are under no obligation to make any Services or features available in any jurisdiction. We reserve the right to modify the Services or any part thereof for any reason, without notice and at any time, including these customer policies.</p>
    
    <p>We do not pre-screen your materials and we have the right to refuse or remove materials from any part of the Services, including your Store.</p>
    
    <p>We may offer our Services to your competitors, but we will never share your confidential information with them in doing so.</p>
    
    <p>Verbal or written abuse of any kind (including threats of abuse, retribution, or disparagement) of Builder or any of our employees, partners, or officers will result in immediate Account or Agreement termination.</p>
    </div>


    <div class="contentText">
    <h3>What if I change my mind?</h3>
    <p>No changes are allowed to the base product provided. Any discussions around changes to your plan should be directed towards Etisalat.</p>
    
    <p>You can add additional features as per our process above on Store+ or additional customisations.</p>
    </div>


    <div class="contentText">
    <h3>What is your process of copyright notices?</h3>
    <p>We respect intellectual property rights and you should too. If we receive a DMCA Notice, we may disable access or remove the allegedly infringing content from your Store. If you don’t think the claim is valid, you can send a counter notification. If you believe one of our merchants or customers is infringing your intellectual property rights, you can send Builder.ai a DMCA Notice. We will expeditiously disable access or remove the content and notify the merchant and counterparty.</p>
    </div>


    <div class="contentText">
    <h3>Changes to policy/FAQ</h3>
    <p>We may change these Customer Policies (FAQ) by posting an updated version of it. Updated Policies/FAQ are effective as soon as we post it.</p>        
    </div>

</div>
