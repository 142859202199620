import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {SearchCountriesPipe} from "./pipes/search-countries.pipe";
import {DataService} from "./services/data.service";
import {CommonService} from "./services/common.service";
import {IndividualConfig, ToastrModule, ToastrService} from "ngx-toastr";
import {ApiRequestService} from "./services/api-request.service";
import {AppDataService} from "./services/app-data.service";
import {HttpRequestService} from "./services/http-request.service";
import {ModelMapperService} from "./services/model-mapper.service";
import {HeaderComponent} from "./components/header/header.component";

const toastrService = {
  success: (
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ) => {},
  error: (
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig>
  ) => {},
};

@NgModule({
  declarations: [
    SearchCountriesPipe,
    HeaderComponent
  ],
  exports: [
    SearchCountriesPipe,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass :'toast-bottom-right'
    })
  ],
  providers: [
    DataService,
    CommonService,
    { provide: ToastrService, useValue: toastrService },
    ApiRequestService,
    AppDataService,
    HttpClient,
    HttpRequestService,
    ModelMapperService
  ]
})
export class SharedModule { }
