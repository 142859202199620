<div class="loginSignScreen" *ngIf="(screenTobeAppear === 'signup') || (screenTobeAppear === 'login')">
    <div class="sectionTitle">
        <h2 *ngIf="(screenTobeAppear === 'signup')">Sign up</h2>
        <h2 *ngIf="(screenTobeAppear === 'login')">Sign In</h2>
    </div>

    <div class="sectionMiddle">
        <div class="feildRow">
            <label>Company email</label>
            <div class="inpurRow">
                <input #email="ngModel" name="email" [(ngModel)]="signup_data.email" autoComplete="off"
                       id='loginEmailInput' type="text" placeholder="Enter your e-mail address"
                       [ngClass]="{'redBorder' : emailExist || (!emailExist && (invalidEmail || (email.touched && checkIfEmailValid())))}"
                       (focusout)="validateEmail()" (focus)="removeErrors()" disabled required />
                <div class="errorMsg" *ngIf="emailExist">Email already exist</div>
                <div class="errorMsg" *ngIf="!emailExist && (invalidEmail || (email.touched && checkIfEmailValid()))">Please enter valid email</div>
            </div>
        </div>

        <div class="feildRow" *ngIf="(screenTobeAppear === 'signup')">
            <label>Name</label>
            <div class="inpurRow">
                <input type="text" #name="ngModel" name="name" [(ngModel)]="signup_data.name"
                       autocomplete="off"  placeholder="Enter your name"
                       [ngClass]="{'redBorder' : (invalidName && !(name.touched && name.invalid)) || (name.touched && name.invalid)}"
                       (focusout)="validateName()" (focus)="removeErrors()" disabled required>
                <div class="errorMsg" *ngIf="invalidName && !(name.touched && name.invalid)">Please enter name</div>
                <div class="errorMsg" *ngIf="name.touched && name.invalid && name.errors['required']">Name cannot be blank</div>
            </div>
        </div>

        <div class="feildRow disable-contact" *ngIf="(screenTobeAppear === 'signup')">
            <label>Phone Number</label>
          <app-phone-number [isSaveButtonClicked]="isSaveButtonClicked" [contactNumber]="signup_data.phone_number"
                            [contactInEtisalatFlow]="signup_data.phone_number" (setContactInUserInfo)="setUserContact($event)"
                            [disableField]="true">
          </app-phone-number>
        </div>

        <div class="notificationArea" *ngIf="(screenTobeAppear === 'signup')">
            <div class="notificationHeading">
                <h3>Do you want to receive notifications</h3>
                <div class="toggleButton" [ngClass]="{ 'active': isNotificationEditMode }"
                     (click)="toggleEditPreferences()">
                </div>
            </div>

           <div class="innerContainer" [ngClass]="{'disabledNotification': !isNotificationEditMode}">
            <div class="notificationItems">
              <div class="itemName">
                <img src="../../../../../../../assets/images/icon-whatsapp-white.svg" alt="whatsApp"> Whatsapp
              </div>
              <div class="ddlContainer">
                <div class="phoneNumberField">
                  <div class="phoneNumberField" [ngClass]="{'redBorder' : (notificationData.whatsapp_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactWhatsapp.touched) &&(!notificationData.whatsapp_phone_number || (notificationData.whatsapp_phone_number && checkIfPhoneInValid(notificationData.whatsapp_phone_number)))))}">
                    <div class="countryFlagCode" (click)="toggleCountriesList($event, 'whatsapp')">
                      <span class="selectedFlag flag-icon flag-icon-squared" [class]="'flag-icon-' + countryISOCode.whatsapp?.toLowerCase()"></span>
                      <div class="countryFlagCodeDropdown" *ngIf="showCountriesList.whatsapp">

                        <ul *ngIf="countriesData">
                          <li *ngFor="let country of countriesData | searchCountries: countryCodeOrName" (click)="setPhoneNumber(country, $event, 'whatsapp')">
                            <span class="flag-icon flag-icon-squared" [class]="'flag-icon-' + country.code.toLowerCase()"></span>
                            {{ country.name }} <span class="countryCode">{{ country.dial_code }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <input class="inputPhone" #contactWhatsapp="ngModel" name="contactWhatsapp" [(ngModel)]="notificationData.whatsapp_phone_number"
                           [ngClass]="{'errorInput1': (notificationData.whatsapp_enabled && isNotificationEditMode && ((contactWhatsapp.touched && !contactNumber) || (contactNumber && checkIfPhoneInValid(notificationData.whatsapp_phone_number))))}"
                           (keypress)="acceptNumberOnly($event, 'whatsapp')" (keyup)="acceptNumberOnly($event, 'whatsapp'); setFlagForDialCode('whatsapp')"
                           [disabled]="!notificationData.whatsapp_enabled" required>
                    <input [ngClass]="!showCountriesList.whatsapp ? 'hide-search-input':'searchByName'" id="hiddenInputBox"
                           [(ngModel)]="countryCodeOrName" #searchNameOrCode name="fname"
                           #fname="ngModel" (keypress)="acceptAlphaNumeric($event)">
                  </div>
                  <div class="inputfield phoneNo">
                    <div class="errorMsgBox">
                      <span class="errorMsg" *ngIf="notificationData.whatsapp_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactWhatsapp.touched) && notificationData.whatsapp_phone_number && checkIfPhoneInValid(notificationData.whatsapp_phone_number))">Please enter valid contact</span>
                      <span class="errorMsg" *ngIf="notificationData.whatsapp_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactWhatsapp.touched) && !notificationData.whatsapp_phone_number)">please enter your contact info</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="toggleButton" [ngClass]="{'active': notificationData.whatsapp_enabled}"
              (click)="toggleRadioButton('whatsapp')"></div>
            </div>

            <div class="notificationItems">
              <div class="itemName">
                <img src="../../../../../../../assets/images/icon-message-white.svg" alt="SMS"> SMS
              </div>
              <div class="ddlContainer">
                <div class="phoneNumberField">
                  <div class="phoneNumberField" [ngClass]="{'redBorder' : (notificationData.sms_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactSMS.touched) &&(!notificationData.sms_phone_number || (notificationData.sms_phone_number && checkIfPhoneInValid(notificationData.sms_phone_number)))))}">
                    <div class="countryFlagCode" (click)="toggleCountriesList($event, 'SMS')">
                      <span class="selectedFlag flag-icon flag-icon-squared" [class]="'flag-icon-' + countryISOCode.sms?.toLowerCase()"></span>
                      <div class="countryFlagCodeDropdown" *ngIf="showCountriesList.sms">

                        <ul *ngIf="countriesData">
                          <li *ngFor="let country of countriesData | searchCountries: countryCodeOrName" (click)="setPhoneNumber(country, $event, 'SMS')">
                            <span class="flag-icon flag-icon-squared" [class]="'flag-icon-' + country.code.toLowerCase()"></span>
                            {{ country.name }} <span class="countryCode">{{ country.dial_code }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <input class="inputPhone" #contactSMS="ngModel" name="contactSMS" [(ngModel)]="notificationData.sms_phone_number"
                           [ngClass]="{'errorInput1': (notificationData.sms_enabled && isNotificationEditMode && ((contactSMS.touched && !contactNumber) || (contactNumber && checkIfPhoneInValid(notificationData.sms_phone_number))))}"
                           (keypress)="acceptNumberOnly($event, 'SMS')" (keyup)="acceptNumberOnly($event, 'SMS'); setFlagForDialCode('SMS')"
                           [disabled]="!notificationData.sms_enabled" required>
                    <input [ngClass]="!showCountriesList.sms ? 'hide-search-input':'searchByName'" id="hiddenInputBox"
                           [(ngModel)]="countryCodeOrName" #searchNameOrCode name="fname"
                           #fname="ngModel" (keypress)="acceptAlphaNumeric($event)">
                  </div>
                  <div class="inputfield phoneNo">
                    <div class="errorMsgBox">
                      <span class="errorMsg" *ngIf="notificationData.sms_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactSMS.touched) && notificationData.sms_phone_number && checkIfPhoneInValid(notificationData.sms_phone_number))">Please enter valid contact</span>
                      <span class="errorMsg" *ngIf="notificationData.sms_enabled && isNotificationEditMode && ((isSaveButtonClicked || contactSMS.touched) && !notificationData.sms_phone_number)">please enter your contact info</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="toggleButton" [ngClass]="{'active': notificationData.sms_enabled}"
              (click)="toggleRadioButton('SMS')"></div>
            </div>
          </div>
        </div>

        <div class="feildRow">
            <div class="forgotPassword">
              <label>Password</label>
              <div class="forgot" *ngIf="(screenTobeAppear === 'login')"
                   (click)="toggleLoginSignup('reset')">Forgot your password?
              </div>
            </div>
            <div class="inpurRow">
              <input [type]="isShowPassword ? 'text' : 'password'" [ngClass]="{'redBorder' : (invalidPassword || (password.touched && password.invalid && (password.errors['minlength'] || password.errors['required'])))}"
                     #password="ngModel" name="password" [(ngModel)]="signup_data.password"
                     (focusout)="validatePassword()" placeholder="Enter password" minlength="8"
                     (focus)="removeErrors()" required>
                <span *ngIf="invalidPassword && !(password.touched && password.invalid)" class="errorMsg"> Password is not valid</span>
                <div *ngIf="password.touched && password.invalid">
                  <span *ngIf="password.errors['minlength']" class="errorMsg"> Password should contain 8 characters</span>
                  <span *ngIf="password.errors['required']" class="errorMsg"> Password cannot be blank </span>
                </div>
              <div class="viewPassword" (click)="showPassword()"><em class="icon-eye"></em></div>
            </div>
        </div>

        <div class="termsCondition" *ngIf="(screenTobeAppear === 'signup')">
            <input type="checkbox" id="etisalatterms" (click)="$event.stopPropagation()"
                   (change)="validateTandCOnCheckboxChange()">
            <label for="etisalatterms">I agree to the
              <span class="linkColor" (click)="openUrls('https://www.builder.ai/privacy')">Privacy Policy</span> and
              <span class="linkColor" (click)="openUrls('https://www.builder.ai/terms')">Terms and Conditions</span></label>
        </div>
        <div class="errorMsgTandC" *ngIf="(screenTobeAppear === 'signup') && !termsAndConditionsChecked && isSaveButtonClicked">
          Please check this box if you want to proceed
        </div>

    </div>

    <div class="sectionFooter">
        <button type="button" *ngIf="(screenTobeAppear === 'signup')" (click)="loginSignup()"
                [ngClass]="{ 'custom-disabled': checkIfToDisableButton() }">
          Create Account
        </button>
        <button type="button" *ngIf="(screenTobeAppear === 'login')" (click)="loginSignup()">Sign in</button>
        <p>
          <span (click)="redirectToMSA()">Terms and Conditions</span> |
          <span (click)="openUrls('https://www.builder.ai/terms/privacy')">Privacy</span> |
          <span (click)="openUrls('https://www.builder.ai/terms/ccpa')">CCPA</span>
        </p>
    </div>
</div>

<div class="loginSignScreen" *ngIf="(screenTobeAppear === 'reset')">
  <div class="sectionTitle">
    <h2>Forgot your password?</h2>
  </div>

  <div class="sectionMiddle">
    <form>
      <div class="subText">Enter your email address below and we'll get you back on track.</div>
      <div class="feildRow">
        <label>E-mail address</label>
        <div class="inpurRow">
          <input #email="ngModel" name="email" [(ngModel)]="signup_data.email" autoComplete="off"
                   id='loginEmailInput' type="text" placeholder="Enter your e-mail address"
                   [ngClass]="{'redBorder' : emailExist || (!emailExist && (invalidEmail || (email.touched && checkIfEmailValid())))}"
                   (focusout)="validateEmail()" (focus)="removeErrors()" required />
          <div class="errorMsg" *ngIf="emailExist">Email already exist</div>
          <div class="errorMsg" *ngIf="!emailExist && (invalidEmail || (email.touched && checkIfEmailValid()))">Please enter valid email</div>
        </div>
      </div>

      <div class="sendResetButton">
        <button type="submit" class="doneButton" (click)="forgotPassword()" [disabled]="checkIfEmailValid()"> Send reset link</button>
      </div>
      <div class="alreadyHave">
        <span (click)="toggleLoginSignup('login')">Back to sign in</span>
      </div>
    </form>
  </div>
</div>

<div class="loginSignScreen" *ngIf="screenTobeAppear === 'resetSuccess'">
  <div class="sectionTitle">
    <h2>Password reset link sent</h2>
  </div>
  <div class="sectionMiddle">
    <div class="subText">We've sent a password reset link to your email, click on the link to confirm.</div>
    <div class="sendResetButton">
      <button type="button" (click)="toggleLoginSignup('login')">Sign in again</button>
    </div>
  </div>
</div>
