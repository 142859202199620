import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import parsePhoneNumber from 'libphonenumber-js';
import {AppDataService} from "../../../shared/services/app-data.service";
import {DataService} from "../../../shared/services/data.service";
import {ApiRequestService} from "../../../shared/services/api-request.service";

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {

  countriesData = [];
  countryCodeOrName = '';
  showCountriesList = false;
  @ViewChild('searchNameOrCode') searchNameOrCode: ElementRef;
  @ViewChild('mobileNumber') public mobileNumber;
  countryISOCode: any;
  @Input() contactNumber: string;
  @Input() isSaveButtonClicked: boolean;
  @Output() setContactInUserInfo = new EventEmitter();
  @Output() closeCountryStateDropDown = new EventEmitter();
  @Input() disableField: boolean;
  @Input() contactInEtisalatFlow: string;
  countryNameCode: any;

  constructor(private apiRequest: ApiRequestService, public dataService: DataService,
              private appDataService: AppDataService) { }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const elementId = (event.target as Element).id;
    if (elementId && elementId.includes('hiddenInputBox')) {
      return false;
    }
    if (this.showCountriesList) {
      this.showCountriesList = false;
      this.countryCodeOrName = '';
    }
  }

  ngOnInit(): void {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.countryNameCode = data.country_code ? data.country_code.toLowerCase() : '';
      this.getCountriesData();
    });
  }

  getCountriesData() {
    this.apiRequest.getCountriesList('../../../../assets/countries.json').subscribe(resp => {
      this.countriesData = resp;
      let countryNameCode = '';
      countryNameCode = this.countriesData.find(country => (country.code.toLowerCase() === this.countryNameCode)).dial_code;
      countryNameCode = countryNameCode.replace('+', '');
      countryNameCode = countryNameCode.replace('+', '').replace(/\s/g, '');
      this.countryNameCode = countryNameCode;
      const defaultCountry = this.countriesData.find(country => country.dial_code.substr(1) === countryNameCode);
      this.setPhoneNumber(defaultCountry);
    });
  }

  setPhoneNumber(country, event?) {
    if (event) {
      event.stopPropagation();
    }
    const user = this.dataService.user;
    if (event || (!user && !this.appDataService.urlParameters.phone_number) || (user && !user.phone_number)) {
      this.contactNumber = country.dial_code;
    }
    if (this.contactInEtisalatFlow) {
      this.contactNumber = this.contactInEtisalatFlow;
    }
    this.dataService.countryISOCode = country.code;
    this.countryNameCode = country.dial_code;
    this.showCountriesList = false;
    this.countryCodeOrName = '';
    this.contactNumber = this.contactNumber.replace(/\s/g, '');
    this.contactNumber = this.contactNumber.replace(this.countryNameCode, (this.countryNameCode + ' '));
    this.setContactInUserInfo.emit(this.contactNumber);
  }

  toggleCountriesList(event) {
    event.stopPropagation();
    this.showCountriesList = !this.showCountriesList;
    this.countryCodeOrName = '';
    if (this.showCountriesList) {
      this.searchNameOrCode.nativeElement.focus();
    }
    this.closeCountryStateDropDown.emit(true);
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.contactNumber.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  acceptNumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode < 48 && charCode !== 43) || charCode > 57)) {
      return false;
    }
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      const dialCode = '+' + phoneNumber.countryCallingCode;
      this.dataService.countryISOCode = this.countriesData.find(country => (country.dial_code === dialCode)).code;
      this.countryNameCode = phoneNumber.countryCallingCode.toString();
    }
    return true;
  }

  setFlagForDialCode() {
    const phoneNumber = parsePhoneNumber(this.contactNumber.toString(), this.dataService.countryISOCode);
    if (!phoneNumber && this.contactNumber) {
      const iSOCode = this.countriesData.find(country => (this.contactNumber.toString().includes(country.dial_code)));
      if (iSOCode) {
        this.dataService.countryISOCode = iSOCode.code;
      } else {
        this.dataService.countryISOCode = '';
      }
    }
    this.setContactInUserInfo.emit(this.contactNumber);
  }

  acceptAlphaNumeric(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode == 32) || (charCode == 43) || (charCode > 47 || charCode < 58) && ((charCode > 64 || charCode < 91)) && ((charCode > 96 || charCode < 123))) {
      return true;
    } else {
      return false;
    }
  }

}
