export class BuildCardModel {
  actual_weeks: number;
  build_card_pdf_url: string;
  bundle_count: number;
  change_amount: number;
  change_price_status: boolean;
  change_week: number;
  change_week_status: boolean;
  competitors: any;
  completion_percentage: number;
  confidence_percentage: number;
  currency: any;
  customized_template_name: string;
  description: string;
  development_state: string;
  discounted_price: number;
  frequency: string;
  invites: any;
  is_approved: boolean;
  is_under_support: boolean;
  feature_count: number;
  team: any;
  speed: any;
  id: number;
  installment_amount: number;
  installment_counts: { monthly: 0, weekly: 0 };
  kick_off_date: string;
  nda_signed: boolean;
  owner: boolean;
  owner_email: string;
  owner_name: string;
  percentage_completed: number;
  phase_installment_paid: boolean;
  pre_kick_off_date: string;
  price: number;
  progress: any;
  progress_count: number;
  project_name: string;
  promotion: any;
  pm_status: string;
  quote: string;
  specing: boolean;
  specing_paid: boolean;
  status: string;
  support_maintenance_price: number;
  support_maintenance_weeks: number;
  target_user_group: string;
  uniq_code: string;
  unseen_section_count: number;
  updated_at: string;
  updated_section_count: number;
  weeks: number;
  build_card_phases: [];
  multiplier_experiment: any;
  change_requests: any[];
  change_requests_status: string;
  features: any;
  payment_weeks: number;
  skip_signing_contract: boolean;
  is_custom_prototype: boolean;
  cloud_price: number;
  cloud_opted: boolean;

}
