import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import moment from 'moment';
import {DataService} from "../../../shared/services/data.service";
import {EtisalatUserService} from "../etisalat-user.service";
import {CommonService} from "../../../shared/services/common.service";
import {ApiRequestService} from "../../../shared/services/api-request.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-kick-off-details',
  templateUrl: './kick-off-details.component.html',
  styleUrls: ['./kick-off-details.component.scss']
})
export class KickOffDetailsComponent implements OnInit {

  isMSASigned = false;
  isMSASignedError = false;
  isMSAChecked = false;
  moment: any;
  showProjectNamePopup = false;
  projectName = '';
  schedulerURL: SafeResourceUrl;
  showBeforeLeavingSchedulerPopup = false;
  showCancelOptions = false;

  constructor(public dataService: DataService, public etisalatUserService: EtisalatUserService,
              private apiService: ApiRequestService, private router: Router,
              private commonService: CommonService, private sanitizer: DomSanitizer) {
    this.moment = moment;
  }

  ngOnInit(): void {
    this.isMSAChecked = this.getKickOffDetails()['nda_signed'];
    this.isMSASigned = this.getKickOffDetails()['nda_signed'];
    this.commonService.etisalatCardRenamed.subscribe((resp) => {
      if (resp) {
        this.setResetDocument();
      }
    });
  }

  openScheduler() {
    if (this.isMSASigned || this.getKickOffDetails()['nda_signed']) {
      this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataService.showTimeKitWindow(false, this.dataService.specingId, 'kickoff'));
      this.dataService.showScheduleCallFlow = true;
      this.dataService.openSchedulerPopup = true;
    } else {
      this.isMSASignedError = true;
    }
  }

  getKickOffDetails() {
    return this.etisalatUserService.userAndKickOffDetails['build_card'];
  }

  setResetDocument() {
    const reqParams = { nda_signed: this.isMSAChecked, source: 'etisalat' },
      buildCardId = this.getKickOffDetails()['id'];
    this.dataService.showHideLoader(true);
    this.apiService.setResetContractsSigned(buildCardId, reqParams).subscribe(() => {
      this.dataService.showHideLoader(false);
      if (this.isMSAChecked) {
        this.isMSASigned = true;
        this.isMSASignedError = false;
      } else {
        this.isMSASigned = false;
        this.isMSASignedError = true;
      }
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  isProNameSameAsOrgName() {
    let organisationName = this.etisalatUserService.userAndKickOffDetails['user']['organisation'];
    organisationName = organisationName.replace(/[^a-zA-Z0-9,;\-.!? ]/g, '').split(' ').join('');
    return (organisationName.includes(this.getKickOffDetails()['project_name']));
  }

  renameBuildCard(event) {
    this.isMSAChecked = event.target.checked;
    if ((this.getKickOffDetails()['status'] === 'card_completed') &&
      ((this.getKickOffDetails()['project_name'] === 'MyBuilderProject') || this.isProNameSameAsOrgName())
      && !(this.dataService.buildCardData && this.dataService.buildCardData.project_name)) {
      this.dataService.inProgressBuildCardId = this.getKickOffDetails()['id'];
      this.showProjectNamePopup = true;
    } else {
      this.setResetDocument();
    }
  }

  goToDashboard() {
    window.location.href = (` ${environment.STUDIO_FRONTEND_URL}dashboard?is_etisalat_flow=true`);
  }

  openUrls(url) {
    window.open(url);
  }

  getFormattedDate() {
    return this.moment(this.getKickOffDetails().scheduled_date).format('DD MMM, YYYY');
  }

  acceptAlphaNumeric(event) {
    if (this.commonService.isEtisalatFlow()) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (((charCode > 64) && (charCode < 91)) || ((charCode > 96) && (charCode < 123)) ||
        ((charCode > 47) && (charCode < 58)) || (charCode === 45)) {
        return true;
      } else {
        return false;
      }
    }
  }

  trackIfUserStartedTyping(event) {
    if (event.target.value && event.keyCode === 13) {
      this.saveProjectName();
    }
  }

  saveProjectName() {
    const payLoad = { build_card: { project_name: this.projectName } };
    this.apiService.updateBuildCardName(payLoad).subscribe(resp => {
      this.dataService.buildCardData = resp.data.attributes;
      this.showProjectNamePopup = false;
      this.setResetDocument();
    }, error => {
      this.showProjectNamePopup = false;
    })
  }

  closeSchedulerPopup() {
    this.dataService.openSchedulerPopup = false;
    this.goToDashboard();
  }

  openBeforeLeavingSchedulerPopup() {
    let callType = 'kickoff';
    this.dataService.showHideLoader(true);
    let specId = this.dataService.specingId;
    this.apiService.confirmSpecCallBooked(callType, specId).subscribe((resp) => {
      this.dataService.showHideLoader(false);
      if (resp.message !== 'success') {
        this.showBeforeLeavingSchedulerPopup = true;
      }else{
        this.closeSchedulerPopup();
      }
    }, error => {
      this.dataService.showHideLoader(false);
      this.showBeforeLeavingSchedulerPopup = true;
    });
  }

  showOptions(){
    this.showCancelOptions = !this.showCancelOptions;
  }

  scheduleCallLater(cancelReason?) {
    let reason = { kickoff_cancel_reason : cancelReason }
    const buildCardId = this.dataService.buildCardData.id ? this.dataService.buildCardData.id : this.dataService.specingId;
    this.dataService.showHideLoader(true);
    this.apiService.kickOffCancelReason(reason, buildCardId).subscribe((data: any) => {
      this.closeSchedulerPopup();
      this.scheduleCallNow();
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  scheduleCallNow() {
    this.showBeforeLeavingSchedulerPopup = false;
  }

  redirectToMSA() {
    const URL = environment.ETISALAT_FE_URL + 'msa';
    window.open(URL, '_blank');
  }

}
