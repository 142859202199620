<div class="kickoffScreen">
    <div class="sectionHolder">
        <div class="alreadyScheduled" *ngIf="getKickOffDetails()?.scheduled_date">
            Your Kick-off call is already scheduled with {{ getKickOffDetails()?.assignee_name }} for
          {{ getKickOffDetails()?.scheduled_call_start }} IST on {{ getFormattedDate() }}.
        </div>
        <h3>What happens in a kick-off call?</h3>
        <ul>
            <li>Meet the expert who'll manage your whole Buildcard<sup class="tm light">®</sup>.</li>
            <li>Go through all your features (so they're exactly right).</li>
            <li>Explore the dashboard that shows you live updates.</li>
            <li>Get all your questions answered.</li>
            <li>Officially start your Buildcard<sup class="tm light">®</sup>.</li>
        </ul>

        <button type="button" *ngIf="getKickOffDetails()?.scheduled_date"(click)="goToDashboard()">
          Go To Dashboard
        </button>

        <ng-container *ngIf="!getKickOffDetails()?.scheduled_date">
          <button type="button" (click)="openScheduler()">Book my kick-off call</button>
          <div class="agreementCheckbox">
            <input type="checkbox" id="agreementterm" (click)="renameBuildCard($event)"
                   [checked]="isMSAChecked">
            <label for="agreementterm">Check here to indicate you have read and agreed to the
              terms of the <span class="linkColor" (click)="redirectToMSA()">
                Terms and Conditions
              </span>
            </label>
          </div>
          <div class="errorMsg" *ngIf="isMSASignedError">
            Please tick above box and name your build card before booking kick-off call.
          </div>
        </ng-container>

    </div>
</div>

<div class="commonPopUp active" *ngIf="showProjectNamePopup">
  <div class="popOverlay bgNone"></div>
  <div class="popHolder buildcardname">
    <div class="cardnameIcon">
      <img src="../../../assets/images/thumb-icon.png" />
    </div>
    <h3>Time to create your Buildcard<sup class="tm light">®</sup> (It describes exactly what you want to build)</h3>
    <p><strong>First, please name your Buildcard<sup class="tm">®</sup></strong></p>
    <input type="text" [(ngModel)]="projectName" (keyup)="trackIfUserStartedTyping($event)"
           maxlength="50" (keypress)="acceptAlphaNumeric($event)" placeholder="eg. Booking.com">
    <button type="button" (click)="saveProjectName()" [disabled]="!projectName">
      Save
    </button>
  </div>
</div>

<div class="commonPopUp active" *ngIf="dataService.openSchedulerPopup">
  <div class="popOverlay"></div>
  <div class="popHolder shedulerPopup">
    <div class="closePopUp" (click)="openBeforeLeavingSchedulerPopup()">
      <span class="icon-cancel"></span>
    </div>
    <div class="shedulerPopupHolder">
      <iframe frameborder="0" [src]="schedulerURL"></iframe>
    </div>
  </div>
</div>

<div class="commonPopUp active" *ngIf="showBeforeLeavingSchedulerPopup">
  <div class="popOverlay"></div>
  <div class="popHolder beforeYouLeavePopup">
    <div class="closePopUp" (click)="scheduleCallNow()"><span class="icon-cancel"></span></div>
    <div>
      <h3>Before you leave</h3>
      <div>
        <p>Hey again <span>{{dataService?.user?.first_name}}.</span></p>
        <p>We can't wait to get started on your Buildcard! We just need to complete your Kickoff call to get going. Can we book yours in now?</p>
      </div>
      <div class="buttonWrap">
        <button type="button" class="btnbookItLater" (click)="showOptions()">
          <span class="selectedReason">No, select reason</span>
          <em class="icon-downside"></em>
          <ul *ngIf="showCancelOptions">
            <li (click)="scheduleCallLater('I am not able to start immediately')">I am not able to start immediately</li>
            <li (click)="scheduleCallLater('I need to check my availability')">I need to check my availability</li>
            <li (click)="scheduleCallLater('I can\'t find a suitable time to book')">I can't find a suitable time to book</li>
          </ul>
        </button>
        <button type="button" class="btnBookNow" (click)="scheduleCallNow()">Yes, let me book now</button>
      </div>
    </div>
  </div>
</div>
