import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  isPlatformBrowser = false;
  isToastPresent = false;
  etisalatCardRenamed: BehaviorSubject<any> = new BehaviorSubject<string>('');

  constructor(private toasterService: ToastrService) { }

  showError(error?) {
   const options = {
      messageClass: 'toast-message',
      closeButton: true,
      timeOut: 2000,
      enableHtml: true,
      maxOpened: 1,
      preventDuplicates: true,
      preventOpenDuplicates: true,
      newestOnTop: true,
      autoDismiss: false,
      onHidden: this.enableToaster()
    };
    if (!this.isToastPresent) {
      this.isToastPresent = true;
      let showMessage = 'Please try after sometime!';
      try {
        showMessage = (error.error.message ? error.error.message : 'Please try after sometime!');
      } catch (e) { showMessage = 'Please try after sometime!'; }
      this.toasterService.error(showMessage, 'Error', options);
    }
  }

  enableToaster() {
    setTimeout(() => { this.isToastPresent = false; }, 2000);
  }

  isEtisalatFlow() {
    return location.href.includes('etisalat');
  }
}
