<app-header></app-header>
<div class="pageLoaderBox" *ngIf="dataService.showLoader">
  <div class="pageLoader"></div>
  <div class="loadingOverlay"></div>
</div>
<div class="etisalatPage">
    <div class="etisalatLeft">
      <div class="topLeftLogo">
        <img src="../../assets/images/builder-logo.png" />
        <p>Powering Etisalat's Apps 360</p>
      </div>
      <div class="topRightLogo">
        <img src="../../assets/images/Etisalat_eand_Logo_EN.svg.png" />
      </div>
    </div>
    <div class="etisalatRight">
        <app-login-signup *ngIf="!etisalatUserService.isEtisalatUserFetched && !etisalatUserService.isInvalidAccess">
        </app-login-signup>
        <app-kick-off-details *ngIf="etisalatUserService.isEtisalatUserFetched && !etisalatUserService.isInvalidAccess">
        </app-kick-off-details>
        <app-unauthorized-screen *ngIf="etisalatUserService.isInvalidAccess && !etisalatUserService.isEtisalatUserFetched">
        </app-unauthorized-screen>
    </div>
</div>
