import { Injectable } from '@angular/core';
import {UserModel} from "../../shared/models/user.model";
import {CommonService} from "../../shared/services/common.service";
import {BehaviorSubject} from "rxjs";
import {BuildCardModel} from "../../shared/models/build-card.model";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  countryNameCode: any;
  country_code: any;
  user: UserModel;
  cookie_domain = null;
  user_cookie = null;
  signupData: any;
  etisalatUserData: any;
  specingId: number;
  countryISOCode: any;
  updateCountryCode: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  openSchedulerPopup = false;
  showScheduleCallFlow = false;
  buildCardData: BuildCardModel = new BuildCardModel();
  inProgressBuildCardId: number;
  token: string;
  showLoader = false;

  constructor(public commonService: CommonService) { }

  storeUserObject() {
    this.setCookie();
  }

  setCookie() {
    this.user_cookie = environment.USER_COOKIE;
    this.user.frontend_url = window.location.origin;
    if (this.user.address) {
      if (this.user.address.street) {
        this.user.address.street = encodeURIComponent(this.user.address.street);
      }
      if (this.user.address.apartment) {
        this.user.address.apartment = encodeURIComponent(this.user.address.apartment);
      }
    }
    const usercookie = JSON.parse(JSON.stringify(this.user));
    if (usercookie.currency && usercookie.currency.symbol) {
      usercookie.currency.symbol = encodeURIComponent(usercookie.currency.symbol);
    }
    if (usercookie.currency && usercookie.bant_answers) {
      usercookie.bant_answers = encodeURIComponent(usercookie.bant_answers);
    }
    delete usercookie.profile_image;
    if (usercookie.available_country) {
      delete usercookie.available_country;
    }
    localStorage.setItem(this.user_cookie, JSON.stringify(usercookie))
    document.cookie = `${this.user_cookie}=${JSON.stringify(this.getcookieData(usercookie))}; path=/; domain=${this.cookie_domain};`;
  }

  getcookieData(usercookie) {
    return   {
      'id' : usercookie.id,
      'pm_dashboard_token' : usercookie.pm_dashboard_token,
      'authtoken' : usercookie.authtoken
    }
  }

  validateEmail(email) {
    if (!email) {
      return;
    }
    const rest = email.substring(0, email.lastIndexOf('@'));
    if (email.indexOf(' ') >= 0) {
      return false;
    }
    if (rest.includes('..') && (rest.includes('"')) && ((rest.charAt(0) !== '"') || (rest.charAt(rest.length - 1) !== '"'))) {
      return false;
    } else if (rest.includes('..') && (rest.includes('\'')) && ((rest.charAt(0) !== '\'') || (rest.charAt(rest.length - 1) !== '\''))) {
      return false;
    } else if (rest.includes('..') && !(/^'|"/.test(rest))) {
      return false;
    }
    if ((rest.charAt(0) === '.') || (rest.charAt(rest.length - 1) === '.')) {
      return false;
    }
    if (email.includes('@') && !(email.split('@')[1].split('.').length >= 2) && (email.split('@')[1].split('.')[email.split('@').length - 1] !== '')) {
      return false;
    }
    if (!rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/["(),:;<>@[\]]/.test(rest))) {
      return true;
    } else if (rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/[(),:;<>@[\]]/.test(rest))) {
      return true;
    } else if (((rest.charAt(1) === '.') || (rest.charAt(rest.length - 2) === '.')) && ((rest.charAt(0) === '"') && (rest.charAt(rest.length - 1) === '"'))) {
      return true;
    } else if (/^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w{1,30})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  showTimeKitWindow(isDemo?: boolean, specingID?: number, callType?: string) {
    // this.showCalendlyPopup = true;
    let params = '';
    if (this.user) {
      const user = this.user;
      params += user.first_name ? ('&name=' + user.first_name + (user.last_name ? (' ' + user.last_name) : '')) : '';
      params += user.email ? ('&email=' + user.email + '') : '';
      params += user.phone_number ? ('&phone=' + user.phone_number) : '';
      params += user.organisation ? ('&company=' + user.organisation) : '';
      params += user.enterprise_lead ? ('&enterprise_tag=true') : ('&enterprise_tag=false');
    }
    if (this.buildCardData.description) {
      params += '&project_idea=' + this.buildCardData.description;
    }
    if (this.buildCardData && this.buildCardData['type'] === 'rental_price' && this.buildCardData['source'] === 'app_detail') {
      params += '&store=true';
    }
    params += '&channel_partner=etisalat';
    if (specingID && specingID > 0) {
      return (this.getSpecCalendlyLink(callType) + '&generic_id=' + specingID + params);
    } else {
      return (this.getSpecCalendlyLink(callType) + '&generic_id');
    }
  }

  getSpecCalendlyLink(callType) {
    let typeOfCall = callType ? callType : 'spec';
    let url = environment.CALENDLY_URL.replace("{type}", typeOfCall);
    return url;
  }

  unsetCookie() {
    this.user_cookie = environment.USER_COOKIE;
    localStorage.removeItem(this.user_cookie);
    document.cookie = `${this.user_cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookie_domain};`;
  }

  showHideLoader(showHide: boolean) {
    setTimeout(() => {
      this.showLoader = showHide;
    });
  }

}
