import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtisalatRoutingModule } from './etisalat-routing.module';
import {LoginSignupComponent} from "./login-signup/login-signup.component";
import {KickOffDetailsComponent} from "./kick-off-details/kick-off-details.component";
import {UnauthorizedScreenComponent} from "./unauthorized-screen/unauthorized-screen.component";
import {PhoneNumberComponent} from "./phone-number/phone-number.component";
import {EtisalatFlowComponent} from "./etisalat-flow.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {DataService} from "../../shared/services/data.service";
import {CommonService} from "../../shared/services/common.service";
import {IndividualConfig, ToastrModule, ToastrService} from "ngx-toastr";

@NgModule({
  declarations: [
    LoginSignupComponent,
    KickOffDetailsComponent,
    UnauthorizedScreenComponent,
    PhoneNumberComponent,
    EtisalatFlowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    EtisalatRoutingModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginSignupComponent,
    KickOffDetailsComponent,
    UnauthorizedScreenComponent,
    PhoneNumberComponent,
    EtisalatFlowComponent
  ]
})
export class EtisalatModule { }
