export class UserModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  nda_signed: boolean;
  source: string;
  phone_number: string;
  signup_stage: string;
  address_disabled: boolean;
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  currency: any;
  authtoken: string;
  signature_request_guid: string;
  signature_request_status: string;
  user_profiles: any[];
  address: any;
  confirmed_at: any;
  pm_dashboard_token: any;
  user_type: string;
  showTutorial: boolean;
  frontend_url: string;
  roles: string;
  sub_region: string;
  billing_entity: string;
  setting: {
    dynamic_pricing: boolean,
    feature_removal_confirmation: boolean,
    is_recommendation_on: boolean,
    avatar_details: any
  };
  bant_answers: any[];
  feedback_answers: any[];
  spec_call_nda_signed: boolean;
  enterprise_lead: boolean;
  available_country: any[];
  profile_image : any;
  referral_code : string;
  referral_code_used : boolean;
  running_project_count: number;
  price_multiplier: number;
  enable_referral_code: boolean;
  billed_as: string;
  gst_number: string;
  vat_number: string;
  pan_number: string;
  tan_number: string;
  tin_number: string;
  registration_type: string;
  sez_doc: any;
  gst_tds_number: string;
  sales_tax_number: string;
  tax_details_disabled: boolean;
  message_preferences: any;
  organisation: string;
}
