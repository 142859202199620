import { Injectable} from '@angular/core';
import {JsonConvert, OperationMode, ValueCheckingMode} from 'json2typescript';
import {isArray, isObject} from 'rxjs/internal-compatibility';
import {from} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {UrlParameterModel} from "../models/url-parameter.model";

/**
 * Created by nitin on 16/12/17.
 */
@Injectable()
export class ModelMapperService {
  public jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
  }

  public mapToUrlParameterModel(mappedObj: UrlParameterModel, jsonObject): UrlParameterModel {
    try {
      mappedObj = this.jsonConvert.deserializeObject(jsonObject, UrlParameterModel);
      return mappedObj;
    } catch (e) {
      console.log((<Error>e));
    }
  }

  public getMappedArrayModel<T>(modelToBeMapped: T[], jsonObject: any[]): T[] {
    if (isArray(jsonObject) && jsonObject.length > 0) {
      from(jsonObject).pipe(pluck('attributes')).subscribe((item: T) => {
        modelToBeMapped.push(item);
      });
    }
    return modelToBeMapped;
  }

  public getMappedObjectModel<T>(modelToBeMapped: T, jsonObject: any): T {
    if (isObject(jsonObject)) {
      modelToBeMapped = jsonObject['attributes'];
    }
    return modelToBeMapped;
  }

  public getMappedModel<T>(modelToBeMapped: T, jsonObject: any): T {
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] && jsonObject[key].hasOwnProperty('data')) {
          const subObj = jsonObject[key]['data'];
          if (isArray(subObj)) {
            let tempArr = [];
            from(subObj).pipe(pluck('attributes')).subscribe((item) => {
              tempArr.push(item);
            });
            modelToBeMapped[key] = tempArr;
          } else if (isObject(subObj)) {
            modelToBeMapped[key] = subObj['attributes'];
          }
        } else {
          modelToBeMapped[key] = jsonObject[key];
        }
      }
    }
    return modelToBeMapped;
  }

}

