import { Injectable } from '@angular/core';
import {Constants} from "../utils/constants";
import {catchError, map} from "rxjs/operators";
import { throwError as observableThrowError } from 'rxjs';
import {environment} from "../../environments/environment";
import {HttpRequestService} from "./http-request.service";
import {AppDataService} from "./app-data.service";
import {Router} from "@angular/router";
import {CommonService} from "./common.service";
import {DataService} from "./data.service";
import {UserModel} from "../models/user.model";
import {ModelMapperService} from "./model-mapper.service";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  constructor(private httpRequest: HttpRequestService, private appDataService: AppDataService,
              private router: Router, private commonService: CommonService,
              private dataService: DataService, public modelMapperService: ModelMapperService) { }

  fetchHomePageData() {
    let API = environment.API_URL + Constants.apiEndPoints.homepage;
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs()).pipe(map((data) => {
      return data;
    }),
    catchError((error: any) => {
      if (this.commonService.isPlatformBrowser) {
        if (error && (error.status === 0 || error.status === 500 || error.status === 502 ||
          error.status === 504)) {
          this.router.navigate(['maintenance']);
        }
      }
      return observableThrowError(error);
    })
  );
  }

  getUserCountryData() {
    const URL = environment.API_URL + `user/available_country`;
    return this.httpRequest.get(
      URL,
      this.appDataService.getRequestOptionArgs()
    );
  }

  getCountriesList(url) {
    return this.httpRequest.get(url);
  }

  getEtisalatUserData(token) {
    const URL = environment.API_URL + Constants.apiEndPoints.etisalat_user_Details + '?token=' + token;
    return this.httpRequest.get(URL).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        return observableThrowError(error);
      }));
  }

  updateEtisalatuser(userdetails, option) {
    const data = { user: {} };
    const API = `${environment.API_URL}users`;
    for (const key in userdetails) {
      if (userdetails.hasOwnProperty(key)) {
        const value = userdetails[key];
        if (value != null) {
          data['user'][key] = ((key === 'phone_number') ? value.replace(this.dataService.countryNameCode, this.dataService.countryNameCode + '-') : value);
        }
        if (this.appDataService.urlParameters.owner_token) {
          data['user']['owner_transfer_token'] = 'true';
        }
      }
    }
    return this.httpRequest.put(API, data, this.appDataService.getRequestOptionArgs('application/json')).pipe(map((res) => {
        const userObj: UserModel = new UserModel();
        if (res.user) {
          res.user = this.modelMapperService.getMappedModel(userObj, res.user);
        }
        if (option === 'signup') {
          this.dataService.signupData = res.user;
        }
        this.setUpUserData(res);
        return res;
      }),
      catchError((error: any) => {
        this.dataService.user = null;
        return observableThrowError(error);
      })
    );
  }

  setUpUserData(data, isNotFromLogin?, isPayment?) {
    if (data && data.user) {
      const userObj: UserModel = new UserModel();
      this.dataService.user = isNotFromLogin
        ? this.modelMapperService.getMappedModel(userObj, data)
        : this.modelMapperService.getMappedModel(userObj, data.user);
    }
    if (isPayment) {
      this.appDataService.urlParameters.authToken =
        this.dataService.user.authtoken;
    }
    this.dataService.storeUserObject();
  }

  login_signup(userdetails, option) {
    if (this.commonService.isPlatformBrowser) {
      if (userdetails.first_name)
        userdetails.first_name = userdetails.first_name.capitalize();
      if (userdetails.last_name)
        userdetails.last_name = userdetails.last_name.capitalize();
      if (userdetails.firstName)
        userdetails.firstName = userdetails.firstName.capitalize();
      if (userdetails.lastName)
        userdetails.lastName = userdetails.lastName.capitalize();
    }

    if (option == "signup") {
      userdetails.phone_number = userdetails.phone_number.replace(/\s/g, "");
    }
    let API;
    let data = {};
    if (option === "login") {
      API = environment.API_URL + Constants.apiEndPoints.user_signin;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    } else if (option === "signup") {
      API = environment.API_URL + Constants.apiEndPoints.user_signup;
      data = { user: {} };
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data["user"][key] =
              key === "phone_number"
                ? value.replace(
                  this.dataService.countryNameCode,
                  this.dataService.countryNameCode + "-"
                )
                : value;
          }
          if (this.appDataService.urlParameters.owner_token) {
            data["user"]["owner_transfer_token"] = "true";
          }
        }
      }
    } else if (option === "forgot") {
      API = environment.API_URL + Constants.apiEndPoints.user_forgot;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    } else if (option === "reset") {
      API = environment.API_URL + Constants.apiEndPoints.user_reset;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    }

    return this.httpRequest
      .post(
        API,
        data,
        this.appDataService.getRequestOptionArgs("application/json")
      )
      .pipe(
        map((res) => {
          const userObj: UserModel = new UserModel();
          if (res.user) {
            res.user = this.modelMapperService.getMappedModel(
              userObj,
              res.user
            );
          }
          if (option === "forgot" || option === "reset") {
            return res;
          } else {
            if (option === "signup") {
              this.dataService.signupData = res.user;
            }
            this.setUpUserData(res);
            return res;
          }
        }),
        catchError((error: any) => {
          this.dataService.user = null;
          return observableThrowError(error);
        })
      );
  }

  setResetContractsSigned(id, reqParams) {
    const API = environment.API_URL + "build_cards/" + id + "/documents";
    return this.httpRequest
      .post(API, reqParams, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {}),
        catchError((error: any) => {
          // this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  updateBuildCardName(payLoad) {
    let API = environment.API_URL + Constants.apiEndPoints.build_card + '/' +
      this.dataService.inProgressBuildCardId;
    return this.httpRequest.put(API, payLoad, this.appDataService.getRequestOptionArgs()).pipe(map((data) => {
        return data;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  confirmSpecCallBooked(callType, specCallId) {
    const headers = new HttpHeaders({
      "secret-key": environment.SCHEDULAR_SECRET_KEY,
    });
    const apiUrl = environment.SCHEDULAR_BASE_URL + "calendar/external/show_booking?generic_id=" + specCallId + "&booking_type=" + callType;
    return this.httpRequest.get(apiUrl, headers).pipe(map((data) => {
        return data;
      },
      catchError((error: any) => {
        return observableThrowError(error);
      })
    )
    );
  }

  kickOffCancelReason(cancelReason, uniqCode) {
    const API = environment.API_URL + "api/v1/build_cards/" + uniqCode + "/kickoff_cancel_reason";
    return this.httpRequest.put(API, cancelReason, this.appDataService.getRequestOptionArgs()).pipe(map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  fetchUser() {
    const API = environment.API_URL + '/loggedin_user';
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs()).pipe(map((res) => {
        const userObj: UserModel = new UserModel();
        if (res.user) {
          res.user = this.modelMapperService.getMappedModel(userObj, res.user);
        }
        this.setUpUserData(res);
        this.dataService.setCookie();
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  getCookie() {
    const nameEQ = environment.USER_COOKIE + "=";
    const ca = decodeURI(document.cookie).split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        c.substring(nameEQ.length, c.length);
        if (JSON.parse(localStorage.getItem(environment.USER_COOKIE))) {
          return JSON.parse(
            localStorage.getItem(environment.USER_COOKIE)
          );
        } else {
          this.dataService.user = JSON.parse(
            c.substring(nameEQ.length, c.length)
          );
          this.fetchUser().subscribe(() => {
            this.getUserData();
          });
          break;
        }
      }
    }
    return null;
  }

  getUserData() {
    this.dataService.user = this.getCookie();
    // localStorage.removeItem(environment.USER_COOKIE);
    // document.cookie = `${environment.USER_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.dataService.cookie_domain};`;
  }

}
