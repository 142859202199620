import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject
export class UrlParameterModel {
  @JsonProperty('email', String, true)
  email: string = null;
  @JsonProperty('token', String, true)
  token: string = null;
  @JsonProperty('email_confirm_token', String, true)
  email_confirm_token: string = null;
  @JsonProperty('loc', String, true)
  location: string = null;
  @JsonProperty('payment_type', String, true)
  paymentType: string = null;
  @JsonProperty('result', Boolean, true)
  result = false;
  @JsonProperty('status', String, true)
  paymentStatus: string = null;
  @JsonProperty('transaction_id', String, true)
  transaction_id: string = null;
  @JsonProperty('product', String, true)
  currentProduct: string = null;
  @JsonProperty('event', String, true)
  ndaSigned: string = null;
  @JsonProperty('record_type', String, true)
  record_type: string = null;
  @JsonProperty('redirect', String, true)
  redirect: string = null;
  @JsonProperty('record_id', String, true)
  record_id: string = null;
  @JsonProperty('promotion', String, true)
  promotionCode: string = null;
  @JsonProperty('exp', String, true)
  expCode: string = null;
  @JsonProperty('featureRequest', String, true)
  featureRequest: string = null;
  @JsonProperty('build_card_id', String, true)
  buildCardId: string = null;
  @JsonProperty('redirect_key', String, true)
  redirect_key: string = null;
  @JsonProperty('user', String, true)
  username: string = null;
  @JsonProperty('invite', String, true)
  invite: string = null;
  @JsonProperty('invite_token', String, true)
  inviteToken: string = null;
  @JsonProperty('authtoken', String, true)
  authToken: string = null;
  @JsonProperty('signup_stage', String, true)
  signup_stage: string = null;
  @JsonProperty('utm_campaign', String, true)
  utm_campaign: string = null;
  @JsonProperty('utm_source', String, true)
  utm_source: string = null;
  @JsonProperty('utm_medium', String, true)
  utm_medium: string = null;
  @JsonProperty('utm_term', String, true)
  utm_term: string = null;
  @JsonProperty('utm_content', String, true)
  utm_content: string = null;
  @JsonProperty('signature_request_guid', String, true)
  signature_request_guid: string = null;
  @JsonProperty('signature_request_status', String, true)
  signature_request_status: string = null;
  @JsonProperty('curPage', String, true)
  curPage: string = null;
  @JsonProperty('templateIds', String, true)
  templateIds: string = null;
  @JsonProperty('featureIds', String, true)
  featureIds: string = null;
  @JsonProperty('teamId', String, true)
  teamId: string = null;
  @JsonProperty('speedId', String, true)
  speedId: string = null;
  @JsonProperty('productId', String, true)
  productId: string = null;
  @JsonProperty('support', String, true)
  support: string = null;
  @JsonProperty('buildPhases', String, true)
  buildPhases: string = null;
  @JsonProperty('platformIds', String, true)
  platformIds: string = null;
  @JsonProperty('admin_access', String, true)
  admin_access: string = null;
  @JsonProperty('payment_id', String, true)
  payment_id: string = null;
  @JsonProperty('uniq_code', String, true)
  uniq_code: string = null;
  @JsonProperty('invitee_code', String, true)
  invitee_code: string = null;
  @JsonProperty('page', String, true)
  page: string = null;
  @JsonProperty('owner_token', String, true)
  owner_token: string = null;
  @JsonProperty('verification_token', String, true)
  verification_token: string = null;
  @JsonProperty('otp_code', String, true)
  otp_code: string = null;
  @JsonProperty('specing_id', String, true)
  specingId: string = null;
  @JsonProperty('setting', String, true)
  setting: string = null;
  @JsonProperty('name', String, true)
  name: string = null;
  @JsonProperty('phone_number', String, true)
  phone_number: string = null;
  @JsonProperty('countryCode', String, true)
  countryCode: string = null;
  @JsonProperty('currency_id', String, true)
  currency_id: string = null;
  @JsonProperty('red', String, true)
  red: string = null;
  @JsonProperty('prototype_promo', String, true)
  prototype_promo: string = null;
  @JsonProperty('spec_promotion', String, true)
  spec_promotion: string = null;
  @JsonProperty('build_card_id', String, true)
  build_card_id: string = null;
  @JsonProperty('prototype', String, true)
  prototype: string = null;
  @JsonProperty('paymentId', String, true)
  paymentId: string = null;
  @JsonProperty('success_page', String, true)
  successPage: string = null;
  @JsonProperty('v2', String, true)
  v2: string = null;
  @JsonProperty('payment_gateway', String, true)
  payment_gateway: string = null;
  @JsonProperty('prototype_payment', String, true)
  prototypePayment: string = null;
  @JsonProperty('is_custom_prototype', String, true)
  isCustomPrototype: string = null;
  @JsonProperty('application', String, true)
  application: string = null;
  @JsonProperty('buildcard', String, true)
  buildcard: string = null;
  @JsonProperty('cpe_user', String, true)
  cpe_user: string = null;
  @JsonProperty('is_sales_user', String, true)
  is_sales_user: string = null;
  @JsonProperty('show_signup', String, true)
  show_signup: string = null;
  @JsonProperty('code', String, true)
  code: string = null;
  @JsonProperty('is_freemium', String, true)
  is_freemium: string = null;
  @JsonProperty('installment_id', String, true)
  installment_id: string = null;
  @JsonProperty('is_ownership_transfer_mail', String, true)
  is_ownership_transfer_mail: string = null;
  @JsonProperty('template_search', String, true)
  template_search: string = null;
  @JsonProperty('reason', String, true)
  schedular_reason: string = null;
  @JsonProperty('show_login', String, true)
  show_login: string = null;
  @JsonProperty('category', String, true)
  category: string = null;
  @JsonProperty('calltype', String, true)
  calltype: string = null;
  @JsonProperty('pay_starter', String, true)
  pay_starter : string = null;
  @JsonProperty('upgrade_to_store', String, true)
  upgrade_to_store : string = null;
  @JsonProperty('upgrade_to_starter', String, true)
  upgrade_to_starter : string = null;
  @JsonProperty('upfront', String, true)
  upfront : string = null;
  @JsonProperty('whatsup_notify', String, true)
  whatsup_notify : boolean = null;
  @JsonProperty('redirect_to_login', String, true)
  redirect_to_login: boolean = null;
  @JsonProperty('promotion', String, true)
  promotion: string = null;
  @JsonProperty('request_id', String, true)
  changeRequest_id : boolean = null;
  @JsonProperty('project_id', String, true)
  project_id : string = null;
  @JsonProperty('app_sub_type', String, true)
  app_sub_type : string = null;
  @JsonProperty('redirection_url', String, true)
  redirection_url : string = null;
  @JsonProperty('show_signin', String, true)
  show_signin : boolean = false;
  @JsonProperty('login_token', String, true)
  login_token : string = null;
  @JsonProperty('partner', String, true)
  partner : string = null;
  @JsonProperty('cancel_call_id', String, true)
  cancel_call_id: string = null;
}


