import { Injectable } from '@angular/core';
import {UrlParameterModel} from "../models/url-parameter.model";
import {environment} from "../../environments/environment";
import {DataService} from "./data.service";
import {CommonService} from "./common.service";
import {HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from "rxjs";
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  public urlParameters: UrlParameterModel;
  public idleState = "Not started.";
  public timedOut = false;
  multiplierChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public dataService: DataService, private commonService: CommonService) {
    this.urlParameters = new UrlParameterModel();
  }

  public setIncomingUrlParameters(urlParameters: UrlParameterModel) {
    if (urlParameters) {
      this.urlParameters = urlParameters;
    }
  }


  public generateAnonymousId() {
    let anonymousId = this.commonService.isPlatformBrowser ? localStorage.getItem("user_id") : null;
    if (anonymousId) {
      return anonymousId;
    } else {
      anonymousId = "Anonymous_Builder_";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 20; i++) {
        anonymousId += possible.charAt(
          Math.floor(Math.random() * possible.length)
        );
      }
      this.commonService.isPlatformBrowser ? localStorage.setItem("user_id", anonymousId) : null;
      return anonymousId;
    }
  }

  public setDomain(){
    const hostfromurl = window.location.hostname;
    this.dataService.user_cookie = environment.USER_COOKIE;
    if(hostfromurl === "localhost"){this.dataService.cookie_domain = "localhost";}
    else {
      const separate = hostfromurl.split(".");
      separate.shift();
      const currentDomain = separate.join('.');
      this.dataService.cookie_domain = "."+currentDomain;
    }
  }

  public getRequestOptionArgs(contentType?: string, isFromUrl?: boolean, isClearBitBPI?: boolean) {
    let headers = new HttpHeaders();
    if (contentType) {
      headers = headers.append("Content-Type", contentType);
     }
    if (isClearBitBPI) {
      headers = headers.append('Authorization', 'Bearer sk_c880f7df8ca8deb674bed39aa76f7771');
    }
    let user;
    if (this.dataService.user) {
      user = this.dataService.user;
    } else if (this.dataService.signupData) {
      user = this.dataService.signupData;
    } else if (location.href.includes('etisalat')) {
      user = this.dataService.etisalatUserData;
    }

    if (user && !isClearBitBPI) {
      const token = isFromUrl
        ? this.urlParameters.authToken
          ? this.urlParameters.authToken
          : user.authtoken
        : user.authtoken;
      if (token) {
        headers = headers.append("authtoken", token);
      }
      /*if (this.dataService.isLambdaURL) {
        headers = headers.append("x-api-key", "WxMdrz3XdA5JocbDNl0Kf9EmtH2Ngbe16DtNutDt");
      }*/
    }
    if (this.urlParameters.partner) {
      headers = headers.append("partner-uniq-code", this.urlParameters.partner);
    }
    return headers;
  }

}
