export const environment = {
  production: false,
  envName: 'staging',
  PM_DASH_URL : checkIfBrowser() ? window['env'].PM_DASH_URL : '',
  API_URL : checkIfBrowser() ? window['env'].API_URL : '',
  CLOUD_API_URL: checkIfBrowser() ? window['env'].CLOUD_API_URL : '',
  LAMBDA_URL : checkIfBrowser() ? window['env'].LAMBDA_URL : '',
  PM_DASHBOARD_AUTH: checkIfBrowser() ? window['env'].PM_DASHBOARD_AUTH : '',
  PAYMENT_URL_INR: checkIfBrowser() ? window['env'].PAYMENT_URL_INR : '',
  CLIENT_URL: checkIfBrowser() ? window['env'].CLIENT_URL : '',
  PAYMENT_URL : checkIfBrowser() ? window['env'].PAYMENT_URL : '',
  PAYMENT_APP_TOKEN: checkIfBrowser() ? window['env'].PAYMENT_APP_TOKEN : '',
  BUILDER_LIVE_URL: checkIfBrowser() ? window['env'].BUILDER_LIVE_URL : '',
  STORE_URL: checkIfBrowser() ? window['env'].STORE_URL : '',
  USER_COOKIE: checkIfBrowser() ? window['env'].USER_COOKIE : '',
  ENABLE_SENTRY: checkIfBrowser() ? window['env'].ENABLE_SENTRY : '',
  STRIPE_PUBLISHABLE_KEY: checkIfBrowser() ? window['env'].STRIPE_PUBLISHABLE_KEY : '',
  FE_URL: checkIfBrowser() ? window['env'].FE_URL : '',
  CLOUD_AUTH_TOKEN: checkIfBrowser() ? window['env'].CLOUD_AUTH_TOKEN : '',
  CALENDLY_URL : checkIfBrowser() ? window['env'].CALENDLY_URL : '',
  SCHEDULAR_BASE_URL: checkIfBrowser() ? window['env'].SCHEDULAR_BASE_URL : '',
  SCHEDULAR_SECRET_KEY: checkIfBrowser() ? window['env'].SCHEDULAR_SECRET_KEY : '',
  STUDIO_FRONTEND_URL: checkIfBrowser() ? window['env'].STUDIO_FRONTEND_URL : '',
  ETISALAT_FE_URL: checkIfBrowser() ? window['env'].ETISALAT_FE_URL : ''
};

function checkIfBrowser() {
  const userAgent = navigator.userAgent;
  return (userAgent.match(/chrome|chromium|crios/i) || userAgent.match(/firefox|fxios/i) || userAgent.match(/safari/i) || userAgent.match(/opr\//i) || userAgent.match(/edg/i));
}
