import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {EtisalatFlowComponent} from "./etisalat-flow/etisalat-flow.component";
import {FaqComponent} from "./faq/faq.component";
import {MsaComponent} from "./msa/msa.component";

const routes: Routes = [
  { path: '', component: EtisalatFlowComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'msa', component: MsaComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
